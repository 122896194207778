import React, { useState, useEffect } from "react";
import {  Box, Typography } from '@mui/material';
import useToolsProductUpload from "../../tools/toolsProductUpload";
import useToolsNotifications from "../../tools/ToolsNotifications";
import ProductTable from "../ProductTable/ProductTable";
import { useSelector } from 'react-redux';
import onneMappingFilterSlice from "../../features/onneMappingFilterSlice";

export default function WorkspaceEditItems({sourceItems, mapping, updateSourceItem}) {
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const {error, success} = useToolsNotifications();
    const {updateProduct} = useToolsProductUpload();
    const [header, setHeader] = useState([]);
    const [processedItems, setProcessedItems] = useState([]);


    useEffect(() => {
        if(sourceItems !== undefined) {
            processHeader(sourceItems);
            processItems(sourceItems);
        }
    }, [sourceItems])

    const processItems = (items) => {
        let processed = [];

        items.map((elem) => {
            elem.item.id = elem.id;
            processed.push(elem.item)
        })

        setProcessedItems(processed);
    }

    const processHeader = (headerData) => {
        const keyTargetFieldId = selectedDataModel.key;
        const keySrcFieldId = mapping[keyTargetFieldId].value;

        let processedHeader = [];

        let preprocessedHeader = [];
        for(const key in headerData) {
            preprocessedHeader = [...new Set([...preprocessedHeader,...Object.keys(headerData[key].item)])]
        }

        for(const key in preprocessedHeader) {
            let targetMapping = undefined;
            for(const targetFieldId in mapping) {
                if(mapping[targetFieldId].value ===  preprocessedHeader[key]) {
                    targetMapping = targetFieldId;
                    break;
                } else {
                    if(mapping[targetFieldId].uom ===  preprocessedHeader[key]) {
                        targetMapping = targetFieldId + "_uom";
                        break;
                    }
                }
            }
            
            if(keySrcFieldId === preprocessedHeader[key]) {
                processedHeader.unshift(
                {
                    field: preprocessedHeader[key],
                    headerName: preprocessedHeader[key],
                    minWidth: 160,
                    align: 'left',
                    editable: false, // TODO
                    renderHeader: () => (
                        <Typography variant="tableHeaderLight">{preprocessedHeader[key]} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
                    )
                })
            } else {
                processedHeader.push(
                {
                    field: preprocessedHeader[key],
                    headerName: preprocessedHeader[key],
                    minWidth: 160,
                    align: 'left',
                    editable: true, // TODO
                    renderHeader: () => (
                        <Typography variant="tableHeaderLight">{preprocessedHeader[key]} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
                    )
                })
            }
        }
        setHeader(processedHeader);
    }

    const showUpdateMessage = () => {
        let msg = "Product field updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    const onCellEditStop = (newValue) => {
        updateProduct(newValue.id, newValue.field, newValue.value ).then((response) => {
            if(!response.error) {
                updateSourceItem(newValue.id, newValue.field, newValue.value);
                showUpdateMessage();
            } else {
                showUpdateMessageErr()
            }
        }).catch((error) => {
            showUpdateMessageServerErr();
        });
    }

    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { paddingLeft: "20px", paddingRight: "20px" };

    return  <Box name="Mapping" height="100%" width="100%">
                <ProductTable onCellEditStop={onCellEditStop} rows={processedItems} header={header} rowCount={processedItems.length} allowExport={false} />
            </Box>;
}
