import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsRegisterApi() {
    const { post, get } = ToolsApi();

    const postRegisterSourceItem = async (type, version, id) => {
        let url = Config.API_URL + "api/source-item/" + id + "/register";
        
        let body = {
            "dataModel": type,
            "version": version
        }
        let query = undefined
        return post(url, query, body);
    };

    const getRegisterStatus = async (hash) => {
        let url = Config.API_URL + "api/validation/hash/" + hash;
        
        let query = undefined
        return get(url, query);
    };

    return {
        postRegisterSourceItem,
        getRegisterStatus
    };
}