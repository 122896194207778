import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsExportApi() {
    const { post, deleteCall } = ToolsApi();

    const postExportSourceItem = async (type, version, id) => {
        let url = Config.API_URL + "api/source-item/" + id + "/convert/pmdf";
        
        let body = {
            "dataModel":type,
            "version": version
        }
        let query = undefined
        return post(url, query, body);
    };

    const deleteSourceItem = async (id) => {
        let url = Config.API_URL + "api/source-item/" + id
        
        let body = {
        }
        let query = undefined
        return deleteCall(url, query, body);
    }

    return {
        postExportSourceItem,
        deleteSourceItem
    };
}