import React, { useState, useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import { Grid, Box, Typography, NativeSelect } from '@mui/material';
import useToolsProductUpload from "../../tools/toolsProductUpload";
import useToolsNotifications from "../../tools/ToolsNotifications";
import ProductTable from "../ProductTable/ProductTable";
import { useSelector } from 'react-redux';
import { Chip, Select, MenuItem, Tooltip  } from '@mui/material';
import ErrorIcon from '@mui/icons-material/WarningAmberRounded';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import PublishPanelRow from "./PublishPanelRow";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CancelIcon from '@mui/icons-material/Cancel';

const STATUS_NOT_REGISTERED = "Not Registered"
const STATUS_REGISTER_PENDING = "REGISTER-PENDING"
const STATUS_REGISTER_ACCEPTED = "REGISTER-ACCEPTED"
const STATUS_REGISTER_INVALID = "REGISTER-INVALID"
const STATUS_PUBLISH_PENDING = "PUBLISH-PENDING"
const STATUS_PUBLISH_ACCEPTED = "PUBLISH-ACCEPTED"
const STATUS_PUBLISH_INVALID = "PUBLISH-INVALID"

const StateIcon = ({state}) => {
    let component = '';
    let registerStyle = {color: "#E16C43"}
    let publishStyle = {color: "rgb(50, 132, 198)"}

    switch(state) {
        case STATUS_NOT_REGISTERED:
            //component = <Tooltip title="Not Registered"><span>Not registered</span></Tooltip>;
            break;
        case STATUS_REGISTER_PENDING:
            component = <Tooltip title="Register Pending"><PendingActionsIcon sx={registerStyle} /></Tooltip>;
            break;
        case STATUS_REGISTER_ACCEPTED:
            component = <Tooltip title="Register Accepted"><SuccessIcon sx={registerStyle} /></Tooltip>;
            break;
        case STATUS_REGISTER_INVALID:
            component = <Tooltip title="Register Invalid"><ErrorIcon sx={registerStyle} /></Tooltip>;
            break;
        case STATUS_PUBLISH_PENDING:
            component = <Tooltip title="Publish Pending"><PendingActionsIcon sx={publishStyle} /></Tooltip>;
            break;
        case STATUS_PUBLISH_ACCEPTED:
            component = <Tooltip title="Publish Accepted"><SuccessIcon sx={publishStyle} /></Tooltip>;;
            break;
        case STATUS_PUBLISH_INVALID:
            component = <Tooltip title="Publish Invalid"><ErrorIcon sx={publishStyle} /></Tooltip>;;
            break;
    }

    return component;
}

export default function PublishManagerViewItems({sourceItems, mapping, loading, handleOnRowClick, setSelectionModel}) {
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const {error, success} = useToolsNotifications();
    const {updateProduct} = useToolsProductUpload();
    const navigate = useNavigate();
    const [header, setHeader] = useState([]);
    const [processedItems, setProcessedItems] = useState([]);


    useEffect(() => {
        if(sourceItems !== undefined) {
            processHeader(sourceItems);
            processItems(sourceItems);
        }
    }, [sourceItems])

    const processItems = (items) => {
        let processed = [];
        
        if(items.length > 0) {
            //console.log(items[0])
        }
        
        if(typeof selectedDataModel.key !== 'undefined' && mapping[selectedDataModel.key] !== undefined) {
            const keyTargetFieldId = selectedDataModel.key;
            const keySrcFieldId = mapping[keyTargetFieldId].value;

            items.map((elem) => {
                elem.item.realId = elem.id;
                elem.item.id = elem.item[keySrcFieldId];
                elem.id = elem.item[keySrcFieldId];
                elem.item.publishedTo = elem.publishedTo
                elem.item.originalStatus = elem.status
                elem.item.hash = elem.pmdfHash
                processed.push({path: [elem.item[keySrcFieldId]], ...elem.item})
                
            })

            setProcessedItems(processed);    
        }
    }

    const processHeader = (headerData) => {
        let colNumber = 5;
        let colCount = 0;
        const keyTargetFieldId = selectedDataModel.key;
        if(mapping[keyTargetFieldId] === undefined) {
            alert('To use the Publish Manager you first need to map "' + keyTargetFieldId + '" field, as is the key field for this data model');
            navigate('/mapping')
            return;
        }
        const keySrcFieldId = mapping[keyTargetFieldId].value;

        let processedHeader = [];

        let preprocessedHeader = [];
        for(const key in headerData) {
            preprocessedHeader = [...new Set([...preprocessedHeader,...Object.keys(headerData[key].item)])]
        }

        let targetMapping = undefined;
        for(const targetFieldId in mapping) {
            if(mapping[targetFieldId].value ===  keySrcFieldId) {
                targetMapping = targetFieldId;
                break;
            }
        }

        processedHeader.unshift(
        {
            field: keySrcFieldId,
            headerName: keySrcFieldId,
            minWidth: 160,
            align: 'left',
            editable: false, // TODO
            renderHeader: () => (
                <Typography variant="tableHeaderLight">{keySrcFieldId} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
            )
        })
        colCount++;
        
        for(const key in preprocessedHeader) {
            if (colCount === colNumber) { break; }
            if(keySrcFieldId !== preprocessedHeader[key]) {
                let targetMapping = undefined;
                for(const targetFieldId in mapping) {
                    if(mapping[targetFieldId].value ===  preprocessedHeader[key]) {
                        targetMapping = targetFieldId;
                        break;
                    } else {
                        if(mapping[targetFieldId].uom ===  preprocessedHeader[key]) {
                            targetMapping = targetFieldId + "_uom";
                            break;
                        }
                    }
                }

                processedHeader.push(
                {
                    field: preprocessedHeader[key],
                    headerName: preprocessedHeader[key],
                    minWidth: 160,
                    align: 'left',
                    editable: true, // TODO
                    renderHeader: () => (
                        <Typography variant="tableHeaderLight">{preprocessedHeader[key]} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
                    )
                })

                colCount++;
            }
        }

        processedHeader.push(
        {
            field: 'publishedTo',
            headerName: 'Published to',
            minWidth: 260,
            align: 'left',
            editable: false, // TODO
            renderHeader: () => (
                <Typography variant="tableHeaderLight">Published to</Typography>
            ),
            renderCell: (row) => {
                
                    return row.row.publishedTo !== undefined && row.row.publishedTo.length > 0 ?
                        <NativeSelect
                            label=""
                            fullWidth
                            defaultValue={row.row.publishedTo[0].gln}
                        >
                            {row.row.publishedTo.map((company) => (
                                <option value={company.gln}>{company.gln}</option>
                            )) }
                        </NativeSelect>
                    :
                        ''                
                                },
        })
        colCount++;

        processedHeader.push(
            {
                field: 'status',
                headerName: 'Errors',
                minWidth: 40,
                align: 'left',
                editable: false, // TODO
                renderHeader: () => (
                    <div><ErrorIcon style={{verticalAlign: 'middle'}} /></div>
                ),
                renderCell: (params) => (
                    <StateIcon state={params.row.originalStatus} />
                ),
            })
            colCount++;

        setHeader(processedHeader);
    }

    const showPanelArea = (row) => {
        let component = ''
        if((row !== undefined && row.publishedTo !== undefined && row.publishedTo.length > 0) || row.originalStatus === STATUS_REGISTER_INVALID){
            component = <PublishPanelRow publishedDataArray={row.publishedTo} errors={row.originalStatus === STATUS_REGISTER_INVALID} hash={row.hash}/>
        }

        return component;
    }



    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { paddingLeft: "20px", paddingRight: "20px" };

    //console.log(processedItems);

    return  <Box container name="Mapping" height="100%" width="100%">
                <ProductTable 
                    hideSelectAll={true}
                    loading={loading} 
                    handleOnRowClick={handleOnRowClick} 
                    setSelectionModel={setSelectionModel} 
                    rows={processedItems} 
                    header={header} 
                    rowCount={processedItems.length} 
                    showPanel={showPanelArea}
                    getId={(r) => {return r.realId}}
                />
            </Box>;
}
