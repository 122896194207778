import React, { useState, useEffect } from "react";
import { Grid, Button, FormLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../features/onneMappingFilterSlice'; 

export default function ReportsFilters() {
    const dispatch = useDispatch();
    const [mappedState, setMappedState] = useState(0); //all
    const [dataType, setDataType] = useState(0); //all

    useEffect(() => {
        dispatch(setFilters({mappedState: mappedState, dataType: dataType}));
    }, [mappedState, dataType])

    return  <Grid container item direction="row" width="100%" height="100%">
                <Grid item>
                    
                </Grid>
            </Grid>
}