import React from "react";
import { Grid, Box, Typography } from '@mui/material';
import useToolsProductUpload from '../../tools/toolsProductUpload';
import { useDispatch } from 'react-redux';
import { setIsProductUploading } from '../../features/productUploadSlice';
import { useSelector } from 'react-redux';

export default function UploadProductBanner({setIsResultAvailable, setMappedValues, setFileUploaded}) {
    const { getMappingDataProducts } = useToolsProductUpload();
    const dispatch = useDispatch();
    const gpc = useSelector((state) => state.gpc.value);
    const gridStyle = {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "inset 0px 4px 4px rgba(222, 222, 222, 0.25)",
        backgroundImage: `url(${"UploadProduct/bg_image.png"})`,
        backgroundSize: "100% 100%"
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();    
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };


    const handleFile = async (fileUploaded) => {
        setFileUploaded(fileUploaded);
        dispatch(setIsProductUploading(true));
        setIsResultAvailable(true);
        const response = await getMappingDataProducts();
        dispatch(setIsProductUploading(false));
        setMappedValues(response);
    }

    return  <Grid container direction="column" style={gridStyle} onClick={handleClick}
                sx={{
                    '&:hover': {
                        '.MuiTypography-root': {
                            color: "#3284C6"
                        },
                        '.addIcon': {
                            content: `url(${"UploadProduct/add_blue.png"})`
                        }
                    }
                }}s
            >
                <input type="file" ref={hiddenFileInput} hidden style={{display: "none"}} onChange={handleChange}/>
                <Box
                    component="div"
                    sx={{
                        textAlign: "center"
                    }}
                >
                    <Box 
                        component="img"
                        className="addIcon"
                        sx={{
                            height: 48,
                            width: 48,
                            marginBottom: 2,
                        }}
                        alt="Add Icon"
                        src="UploadProduct/add.png"
                    />
                    <Typography textAlign="center" fontSize="22px" color="#4A4F53" fontWeight="600">Drag & Drop your files here</Typography>
                    <Typography textAlign="center" fontSize="16px" color="#4A4F53" fontWeight="400">or upload from desktop</Typography>
                </Box>
            </Grid>;
}   