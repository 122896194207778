import React, { useState, useEffect } from "react";
import OnneList from "../OnneList/OnneList";
import { Grid, Box, Typography } from '@mui/material';
import toolsMappingApi from "../../tools/toolsMappingApi";
import ProductTable from "../ProductTable/ProductTable";
import { useSelector } from 'react-redux';

export default function ReportsList() {
    const {  getDataModel, getSourceItems, getMapping } = toolsMappingApi();
    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const [dataModel, setDataModel] = useState(undefined);
    const [type, setType] = useState(undefined);
    const [version, setVersion] = useState(undefined);
    const [indexedDataModel, setIndexedDataModel] = useState(undefined);
    const [dataMapping, setDataMapping] = useState(undefined);
    const [processedDataMapping, setProcessedDataMapping] = useState(undefined);
    const [sourceItems, setSourceItems] = useState(undefined);
    const [processedItems, setProcessedItems] = useState(undefined);
    const [header, setHeader] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("LOADING: " + loading)
    }, [loading])

    useEffect(() =>{
        if(processedItems !== undefined) {
            setLoading(false);
        }
    }, [processedItems])

    const filterSourceItemsByGpc = (gpcCode, items, pdm, idm, mapping) => {
        let processed = items;
        console.log(processed)
        console.log(selectedDataModel)
        console.log(dataMapping)
        if(selectedDataModel.classification) {
            const classificationTargetFieldId = selectedDataModel.classification;
            if(dataMapping[classificationTargetFieldId]) {
                processed = [];
                const classificationSrcFieldId = dataMapping[classificationTargetFieldId].value;
                console.log(classificationSrcFieldId)
                console.log(gpcCode)
                if(classificationTargetFieldId !== undefined && classificationSrcFieldId !== undefined) {
                    processed = items.filter(function(element) { return element.item[classificationSrcFieldId] == gpcCode })
                }
            }
        }

        console.log(processed)
        processItems(processed, idm, mapping)
    }

    const currentDate = () => {
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };

        return new Date().toLocaleString(undefined,options) + "";
    }

    const checkDefaults = (mapItem) => {
        let defaultValue = ''
        if(mapItem.default !== undefined) {
            switch(mapItem.default.type) {
                case "string":
                    defaultValue = mapItem.default.value;
                    break;
                case "currentDate":
                    defaultValue = currentDate();
                    break;
            }
        }
        return defaultValue;
    }

    const processItems = (items, idm, mapping) => {
        let processed = [];
        let index = 0;

        items.map((item) => {
            let obj = {}
            for(const i in idm) {
                if(mapping[i] !== null /*&& idm[mapping[i]] !== undefined && item[idm[mapping[i]]] !== undefined*/) {
                    obj[idm[i].fieldId + '-' + idm[i].name] = "";
                    obj["id"] = index;
                }
            }
            for(const i in mapping) {
                if(mapping[i] !== null && idm[i] !== undefined /*&& item[idm[mapping[i]]] !== undefined*/) {
                    obj[idm[i].fieldId + '-' + idm[i].name] = mapping[i].value !== undefined && item.item[mapping[i].value] && item.item[mapping[i].value] !== '' ? item.item[mapping[i].value] : checkDefaults(mapping[i]);
                }
            }
            processed.push(obj);
            index++;
        })

        setProcessedItems(processed)
    }

    const processMapping = (mappingData) => {
        let processed = {};

        for(const key in mappingData) {
            if(mappingData[key] !== null) {
                processed[mappingData[key]] = key;
            }
        }
        return mappingData;
    }

    const processHeader = (headerData) => {
        let processedHeader = [];

        for(const key in headerData) {
            let name = (headerData[key].name !== 'propertyCode' && headerData[key].name !== 'propertyInteger' && headerData[key].name !== 'propertyMeasurement') ? headerData[key].name : headerData[key].longName
            processedHeader.push(
            {
                field: headerData[key].fieldId + '-' + name,
                headerName: name,
                minWidth: 160,
                align: 'left',
                renderHeader: () => (
                    <Typography variant="tableHeaderLight">{name}</Typography>
                )
            })
        }
        setHeader(processedHeader);
    }

    const processIndexedDataModel = (dataModel) => {
        let indexed = {}
        dataModel.map((data, i) => {
            indexed[data.fieldId] = data
        })
        setIndexedDataModel(indexed)
        return indexed
    }

    useEffect(() => {
        setLoading(true);
        if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined && gpc !== undefined) {
            getMapping(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    let pdm = processMapping(data.payload)
                    setDataMapping(pdm)
                    setProcessedDataMapping(data.payload)
                }
            });

            getDataModel(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    setDataModel(data.payload)
                }
            })
        }
        
    }, [selectedDataModel,gpc])

    useEffect(() => {
        if(dataMapping !== undefined) {
            getSourceItems({gpcCode: gpc}).then((data) => {
                setSourceItems(data.payload)
            });
        }
    }, [dataMapping])

    useEffect(() => {
        if(dataModel !== undefined) {
            processIndexedDataModel(dataModel)
            processHeader(dataModel)
        }
    },[dataModel])

    useEffect(() => {
        if(sourceItems !== undefined  && dataMapping !== undefined && dataMapping !== null && processedDataMapping !== undefined && indexedDataModel !== undefined) {
            processItems(sourceItems, indexedDataModel, dataMapping)
        }
    }, [sourceItems, processedDataMapping, indexedDataModel, dataMapping])

    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { paddingLeft: "20px", paddingRight: "20px" };

    return  <Box name="Mapping" height="100%" width="100%">
                <ProductTable 
                rows={processedItems !== undefined ? processedItems : []}
                loading={loading}
                header={header} 
                dataModel={dataModel} 
                dataMapping={dataMapping} 
                rowCount={processedItems !== undefined ? processedItems.length : 0} 
                allowExport={true} />
            </Box>;
}
