import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import ProfileBox from "../ProfileBox/ProfileBox";
import tsLogo from "../../img/tslogo.png";
import iconDashboard from "../../img/icons/dashboard.svg"
import iconMapping from "../../img/icons/mapping.svg"
import iconUpload from "../../img/icons/upload.svg"
import Menu from "../Menu/Menu"

export default function Header() {
    let mainStyle = {
        height : "100%", 
        minHeight : "100%",
        backgroundColor: "#3D3D3D"
    };
    let titleStyle = {
        justifyContent: "left", 
        alignItems:"center", 
        height : "100%",
        maxHeight: "75px",
        backgroundColor:"#286DA5",
        paddingLeft:"25px"
    };
    let profileStyle = {
        cursor: "pointer",
        justifyContent: "left", 
        alignItems:"center",
        height : "100%",
        maxHeight: "75px",
        color: "white",
        backgroundColor:"#3284C6",
        paddingLeft: "20px"
    };
    let profileStyleActive = {
        cursor: "pointer",
        justifyContent: "left",
        alignItems:"center",
        height : "100%",
        maxHeight: "75px",
        color: "white",
        backgroundColor:"#1D5684",
        paddingLeft: "20px"
    };

    let iconStyle = {
        marginRight: "10px"
    };

    return  <Grid container direction="column" style={mainStyle}>
                <Menu />
            </Grid>;
}