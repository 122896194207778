import React, { useState, useEffect } from "react";
import { DataGridPro, GridActionsCellItem, GridToolbarQuickFilter, GridToolbarContainer, GridToolbar, GridToolbarFilterButton, GridToolbarDensitySelector, useGridApiContext, useGridRootProps, } from '@mui/x-data-grid-pro';
import { Grid, Box, Typography, Button, Snackbar, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate  } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useToolsCustomDatamodelUpload from '../../tools/toolsCustomDatamodelUpload';
import { setRefreshDataModels } from '../../features/refreshDataModelsSlice';
import useToolsNotifications from "../../tools/ToolsNotifications";

const DEFAULT_STATE = "Default"
const CUSTOM_STATE = "Custom"

function StateLabel({text}) {
    const color = {"Default": "#1EB1E033", "Custom": "#DCE01E33"}
    const styleText = {
        fontFamily: 'Figtree',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "9.85833px",
        lineheight: "12px",
        color: "#000000",

    }
    const styleContainer = {
        padding: "7.6px",
        background: color[text],
        paddingLeft: "25px",
        paddingRight: "25px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    }

    return <Box sx={styleContainer}><Typography sx={styleText}>{text}</Typography></Box>
}



export default function TMMList() {
    const {success} = useToolsNotifications();
    const dms = useSelector((state) => state.dataModel.value);
    const { deleteDataModel } = useToolsCustomDatamodelUpload();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const [deletedDMs, setDeletedDMs] = useState(0);
    const [deletedRowsCount, setDeletedRowsCount] = useState(undefined);

    const handleAdd = () => {
        navigate('/add-custom-datamodel')
    }

    const handleDelete = () => {
        selectedRows.map((selectedId, index) => {
            dms.find((element) => {
                if(element.type + "_" + element.version === selectedId) {
                    deleteDataModel(element.type, element.version).then((data) => {
                        console.log(data)
                        if(data.error !== false) {
                            console.log(data);
                        } else {
                            console.log(selectedId)
                            console.log(index)
                            setDeletedRowsCount(index)
                        }
                    });
                }
            })
        })
    }

    const handleSelection = (ids) => {
        setSelectedRows(ids);
    }

    useEffect(() => {
        if(deletedDMs > 0) {
            showNotification(deletedDMs)
            dispatch(setRefreshDataModels(true));
        }
    }, [deletedDMs])

    useEffect(() => {
        if(deletedRowsCount+1 === selectedRows.length) {
            setDeletedDMs(selectedRows.length);
        }
    }, [deletedRowsCount])

    const buttonStyleBlue = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#1EB1E0",
        fontSize: "12px",
        fontWeight: "400",
        width: "135px",
        marginLeft: "10px",
        borderRadius: "0px",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    const buttonStyleRed = {
        backgroundColor: "#FFF",
        border: "solid 1px #BB0000",
        color: "#BB0000",
        fontSize: "12px",
        fontWeight: "400",
        width: "135px",
        marginLeft: "10px",
        borderRadius: "0px",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    const CustomTools = () => {      
        return (
          <Grid sx={{marginBottom: "25px"}}>
            <Button sx={buttonStyleBlue} onClick={handleAdd}>+ Add New</Button>
            {selectedRows.length > 0 ? 
                    <Button sx={buttonStyleRed} onClick={handleDelete} >Delete</Button> 
                : 
                    <Button sx={buttonStyleRed} onClick={handleDelete} disabled>Delete</Button>
            }
            
          </Grid>
        );
    };

    const showNotification = (qty) => {
        let msg = "Deleted " + qty + " Data Model(s) successfully."
        success(msg);
        setDeletedDMs(0);
    }

    const nameStyle = {
        fontFamily: 'Figtree',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",/* 
        textDecorationLine: "underline",
        color: "#3284C6", */
    }

    const headerStyle = {
        fontFamily: 'Figtree',
        margin: "0",
        color: "#1EB1E0",
        fontWeight: "700",
        /* fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#3284C6", */
    }

    const columns = [
        {
            field: 'target_model',
            headerName: 'Target Model',
            headerAlign: 'left',
            renderHeader: () => (
                <Typography variant="selected">Target Model</Typography>
            ),
            align: 'left',
            width: 250,
            renderCell: (params) => {return params.row.type + "_" + params.row.version}
        },
        {
            field: 'creationDate ',
            headerName: 'Creation Date',
            headerAlign: 'left',
            renderHeader: () => (
                <Typography variant="selected">Creation Date</Typography>
            ),
            align: 'left',
            width: 200,
        },
        {
            field: 'key',
            headerName: 'Key',
            headerAlign: 'left',
            renderHeader: () => (
                <Typography variant="selected">Key</Typography>
            ),
            align: 'left',
            width: 200,
        },
        {
            field: 'classification',
            headerName: 'Classification',
            headerAlign: 'left',
            renderHeader: () => (
                <Typography variant="selected">Classification</Typography>
            ),
            align: 'left',
            width: 200,
        },
        {
            field: 'label',
            headerName: 'Label',
            headerAlign: 'left',
            renderHeader: () => (
                <Typography variant="selected">Label</Typography>
            ),
            align: 'left',
            width: 200,
            renderCell: (params) => {return params.row.type !== "GS1" ? <StateLabel text={CUSTOM_STATE}/> : <StateLabel text={DEFAULT_STATE} />}
        },
    ]

    return  <Box name="Mapping" height="calc(100% - 75px)" width="100%">
                {dms !== undefined ? 
                    <Grid container direction="column" height="100%">
                        <CustomTools />
                        <DataGridPro
                            isRowSelectable={(params) => params.row.type !== "GS1"}
                            checkboxSelection
                            rows={dms}
                            columns={columns}
                            onSelectionModelChange={(ids) => {handleSelection(ids)}}
                            getRowId={(row) => {return row.type + "_" + row.version}}
                        />
                    </Grid>
                :
                    <CircularProgress />
                }
            </Box>;
}