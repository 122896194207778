import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setIsProductUploading } from '../../features/productUploadSlice';
import readXlsxFile from 'read-excel-file'
import useToolsNotifications from "../../tools/ToolsNotifications";
import Papa from "papaparse";

export default function UploadCustomDataModelBanner({setIsResultAvailable, setCustomDataModel}) {
    const dispatch = useDispatch();
    const {error, success} = useToolsNotifications();
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState(undefined);

    const processFile = (rows) => {
        let result = [];
        if( rows.length > 1) {
            result = rows.map((elem) => {
                return elem[0];
            })
        } else {
            result = rows[0]
        }
        setCustomDataModel(result);
    }

    const handleClick = () => {
        hiddenFileInput.current.click();    
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };

    const handleFile = (fileUploaded) => {
        setFile(fileUploaded);
    }

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Filetype Error: Valid filetypes are xlsx, xls and csv.";
        error(msg);
    }

    useEffect(() => {
        if( file !== undefined) {
            dispatch(setIsProductUploading(true));
            setIsResultAvailable(true);
            if(file.name.includes(".xlsx") || file.name.includes(".xls")) {
                readXlsxFile(file).then((rows) => {
                    processFile(rows);
                })
            } else {
                if(file.name.includes(".csv")) {
                    const csv = Papa.parse(file, {
                        complete: function(results) {
                            processFile(results.data);
                        }
                    });
                } else {
                    showUpdateMessageErr();
                }
            }
            
            dispatch(setIsProductUploading(false));
        }
    }, [file])

    const gridStyle = {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "inset 0px 4px 4px rgba(222, 222, 222, 0.25)",
        backgroundImage: `url(${"UploadProduct/bg_image.png"})`,
        backgroundSize: "100% 100%"
    }

    return  <Grid container direction="column" style={gridStyle} onClick={handleClick}
                sx={{
                    '&:hover': {
                        '.MuiTypography-root': {
                            color: "#3284C6"
                        },
                        '.addIcon': {
                            content: `url(${"UploadProduct/add_blue.png"})`
                        }
                    }
                }}s
            >
                <input type="file" ref={hiddenFileInput} hidden style={{display: "none"}} onChange={handleChange}/>
                <Box
                    component="div"
                    sx={{
                        textAlign: "center"
                    }}
                >
                    <Box 
                        component="img"
                        className="addIcon"
                        sx={{
                            height: 48,
                            width: 48,
                            marginBottom: 2,
                        }}
                        alt="Add Icon"
                        src="UploadProduct/add.png"
                    />
                    <Typography textAlign="center" fontSize="22px" color="#4A4F53" fontWeight="600">Drag & Drop your files here</Typography>
                    <Typography textAlign="center" fontSize="16px" color="#4A4F53" fontWeight="400">or upload from desktop</Typography>
                </Box>
            </Grid>;
}   