import React, { useState, useEffect } from "react";
import { Grid, Typography} from "@mui/material";
import toolsUserApi from "../../tools/toolsUserApi";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useToken from '../App/useToken';
import { useNavigate  } from 'react-router-dom';
import useUser from '../App/useUser';
import {CircularProgress} from "@mui/material";
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: 400,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      paddingTop: 20,
      paddingBottom: 20,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function User() {
    const dispatch = useDispatch();
    const { user, setUser } = useUser();
    const {setToken} = useToken();
    const navigate = useNavigate();
    const { getUserData } = toolsUserApi();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let titleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    let subtitleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    const handleLogout = () => {
      setToken(undefined);
      setUser({"name" : undefined, "email" : undefined, "gln" : undefined});
      navigate('/login');
    }

    const gotoProfile = () => {
      dispatch(setSelectedIndex("Profile"));
      navigate('/profile');
    }

    return  user !== undefined ?
              <Grid container direction="row" xs={12} height={"100%"} alignItems={"center"}>
                  <Grid item xs={2} className="align-right pr-10">
                    <Button
                        id="profile-button"
                        aria-controls={open ? 'dropdown-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        disableElevation
                        onClick={gotoProfile}
                    >
                      <PersonOutlineIcon />
                    </Button>
                  </Grid>
                  <Grid item container direction="column" xs={8}>
                      <Grid item>
                          {user.name !== undefined ? 
                            <Typography variant="h1" component="h1" style={titleStyle} >
                                Hello {user.name}!
                            </Typography>
                            :
                            ''
                          }
                      </Grid>
                      <Grid item>
                          {user.gln !== undefined ? 
                            <Typography variant="h1" component="h1" style={subtitleStyle} >
                              {user.gln}
                            </Typography>
                            :
                            ''
                          }
                      </Grid>
                  </Grid>
                  <Grid item xs={2}>
                      <Button
                          id="dropdown-arrow-button"
                          aria-controls={open ? 'dropdown-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          disableElevation
                          onClick={handleClick}
                          height="100%"
                      >
                          <Typography variant="p" component="p" style={subtitleStyle} >
                              <KeyboardArrowDownIcon />
                          </Typography>
                      </Button>
                      <StyledMenu
                          id="dropdown-menu"
                          MenuListProps={{
                              'aria-labelledby': 'dropdown-arrow-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                      >
                          <MenuItem onClick={gotoProfile} disableRipple>
                              <PersonOutlineIcon /> Profile
                          </MenuItem>
                          <MenuItem onClick={handleLogout} disableRipple>
                              <LogoutIcon /> Logout
                          </MenuItem>
                      </StyledMenu>
                  </Grid>
              </Grid>
            :
              <CircularProgress style={{width: "320px", height: "320px", color: "#d6e4ef"}} />
}