import React, { useState, useEffect } from "react";
import { Grid, Box, Select, MenuItem, TextField, Button, Tooltip, Typography } from '@mui/material';
import toolsDataModelApi from "../../tools/toolsDataModelApi";
import { useSelector } from 'react-redux';
import {CircularProgress} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import useToolsNotifications from "../../tools/ToolsNotifications";
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "Dashboard";

export default function Dashboard() {
    const dispatch = useDispatch();
    const {error, success} = useToolsNotifications();
    const { getDataModelPicklist, getPicklistRules, postPicklistRules, } = toolsDataModelApi();
    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const [type, setType] = useState(undefined);
    const [version, setVersion] = useState(undefined);
    const [picklist, setPicklist] = useState(undefined)
    const [picklistKeys, setPicklistKeys] = useState(undefined)
    const [selectedPicklist, setSelectedPicklist] = useState(undefined)

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    useEffect(() => {
        if(selectedDataModel !== undefined) {
            setType(selectedDataModel.type);
            setVersion(selectedDataModel.version);
        }
    }, [selectedDataModel])

    useEffect(() => {
        if(type !== undefined && version !== undefined) {
            getDataModelPicklist(type, version, gpc).then((data) => {
                if(!data.error) {
                    let processed = {}
                    let keys = []
                    Object.keys(data.payload).map((pick) => {
                        processed[data.payload[pick][0].picklistId] = {}
                        keys.push({key: data.payload[pick][0].picklistId, label: pick})
                        data.payload[pick].map(elem => {
                            processed[elem.picklistId][elem.code] = elem;
                            processed[elem.picklistId][elem.code].conversion = '';
                            processed[elem.picklistId][elem.code].edited = false;
                        })
                    })
                    setPicklist(processed);
                    setPicklistKeys(keys);
                    setSelectedPicklist(keys[0].key);
                }
            })
        }
    }, [type, version])

    useEffect(() => {
        if(selectedPicklist !== undefined){
            getPicklistRules(type, version, gpc, selectedPicklist).then((data) => {
                if(!data.error) {
                    let copyOfObject = { ...picklist };
                    Object.keys(data.payload).map((value) => {
                        copyOfObject[selectedPicklist][data.payload[value]].conversion = value;
                        copyOfObject[selectedPicklist][data.payload[value]].edited = false;
                    })
                    setPicklist(copyOfObject)  
                }             
            });
        }
    }, [selectedPicklist])

    const handleConversion = (value, pos) => {
        let copyOfObject = { ...picklist };
        copyOfObject[selectedPicklist][pos].conversion = value;
        copyOfObject[selectedPicklist][pos].edited = true;

        setPicklist(copyOfObject);
    }

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    const savePickList = () => { 
        let conversions = {
            rules: {}
        }
        Object.keys(picklist[selectedPicklist]).map((fieldId) => {
            if(picklist[selectedPicklist][fieldId].conversion !== undefined && picklist[selectedPicklist][fieldId].conversion !== '') {
                conversions.rules[ picklist[selectedPicklist][fieldId].conversion] = fieldId;
            }
        })
        
        postPicklistRules(type, version, gpc, selectedPicklist, conversions).then((data) => {
            if(!data.error) {
                let copyOfObject = { ...picklist };
                Object.keys(copyOfObject[selectedPicklist]).map((code) => {
                    copyOfObject[selectedPicklist][code].edited = false;
                })
                setPicklist(copyOfObject)  
                showUpdateMessage();
            }else {
                showUpdateMessageErr();
            }      
        }).catch((error) => {
            showUpdateMessageServerErr();
        });

        
    }
    
    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        padding: '17px 45px 16px 45px'
    }

    const titleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    const mainBoxStyle = {
        height: "calc(100% - 75px)",
        width: '100%',
        overflowY: 'scroll',
    }

    return  <Box name="Dashboard" sx={mainBoxStyle}>
                {picklist !== undefined ?
                        <Grid container direction="column" xs={12} rowSpacing={2} padding={5}>
                            <Grid item container>
                                <Typography variant="h1" component="h1" style={titleStyle}>
                                    Picklist conversion rules
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography variant="p" component="p" >
                                    Here you can set conversion rules for your data to specific picklist entries as defined in the selected datamodel.<br/>
                                    Use the input values to set the value expected in your data to be mapped to the value that appears in the right column<br/>
                                    <br/>
                                    Example: you can convert your data that comes as Yes to TRUE in a specific picklist
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} direction="row" columnSpacing={1}>
                                <Grid item>
                                    <Select
                                        value={selectedPicklist}
                                        label=""
                                        onChange={(e) => {setSelectedPicklist(e.target.value)}}
                                    >
                                        {picklistKeys.map((op) => (
                                            <MenuItem value={op.key}>{op.label}</MenuItem>
                                        )) }
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        sx={buttonStyle} 
                                        onClick={savePickList}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} rowSpacing={1} >
                                {picklist[selectedPicklist] !== undefined ? 
                                        Object.keys(picklist[selectedPicklist]).map((fieldId) => (
                                            <Grid container item xs={12} direction="row" columnSpacing={3} alignItems={"center"}>
                                                <Grid container item xs={4}>
                                                    <TextField 
                                                        label="Value"
                                                        id={fieldId+"text"} 
                                                        variant="outlined" 
                                                        value={picklist[selectedPicklist][fieldId].conversion}
                                                        color={picklist[selectedPicklist][fieldId].edited ? "warning" : picklist[selectedPicklist][fieldId].conversion !== "" && picklist[selectedPicklist][fieldId].conversion !== undefined ? "success" : ""}
                                                        focused={picklist[selectedPicklist][fieldId].edited ? true : picklist[selectedPicklist][fieldId].conversion !== "" && picklist[selectedPicklist][fieldId].conversion !== undefined ? true : false}
                                                        onChange={(e) => {handleConversion(e.target.value, fieldId)}}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid container item xs={8}>
                                                    {fieldId}
                                                    <Tooltip title={picklist[selectedPicklist][fieldId].englishInfo} placement="top" sx={{marginLeft: "5px"}}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        ))
                                    :
                                        ''
                                }
                            </Grid> 
                        </Grid>
                    :
                        <Grid container width="100%" height="100%" alignContent={"center"} justifyContent={"center"}>
                            <Grid item>
                                <CircularProgress style={{width: "100px", height: "100px", color: "#d6e4ef"}} />
                            </Grid>
                        </Grid>
                }
            </Box>;
}
