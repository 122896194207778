import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridRowModes, gridClasses  } from '@mui/x-data-grid-pro';

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#F8F8F8",
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#FFFFFF",
  },
}));

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

export default function ProductTable({hideSelectAll, onCellEditStop, page, rows , totalRows, onPageChange, handleOnRowClick, loading, header, allowExport, setSelectionModel, selectionModel, tree, showPanel, getId }) {
    
    const columns = [
        {
            field: 'GTIN',
            headerName: 'GTIN',
            minWidth: 170,
            align: 'left',
        }
    ];

    let modifiers = tree ? 
        {
            'treeData': 'treeData',
            getTreeDataPath: (row) => row.path
        }
    :{};

    modifiers = showPanel !== undefined ? 
    {
        getDetailPanelContent: ({ row }) => showPanel(row), 'getDetailPanelHeight': () => 'auto'
    } : modifiers;

  return (
    <div style={{ height: 'calc(100% - 112px)', width: '100%' }}>
      <StripedDataGrid
        {...modifiers}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: hideSelectAll !== undefined ? "none" : ""
            }
        }}
        rowModesModel={{ 1: { mode: GridRowModes.Edit } }}
        onCellEditCommit={(e) => onCellEditStop(e)}
        onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        onRowClick={handleOnRowClick}
        loading={loading}
        rowCount={totalRows}
        paginationMode="server"
        onPageChange={onPageChange}
        rows={rows}
        columns={header === undefined ? columns : header}
        page={page}
        getRowId={(r) => getId !== undefined ? getId(r) : r.id}
        pageSize={25}
        rowsPerPageOptions={[25]}
        checkboxSelection
        disableSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        components={{
            Toolbar: allowExport? CustomToolbar : undefined,
        }}
      />
    </div>
  );
}