import React, { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Tooltip,Typography, Autocomplete, Select, MenuItem, Switch } from '@mui/material';
import toolsDataModelApi from "../../tools/toolsDataModelApi";
import { useSelector } from 'react-redux';
import useToolsNotifications from "../../tools/ToolsNotifications";
import InfoIcon from '@mui/icons-material/Info';
import {CircularProgress} from "@mui/material";

export default function OnnePicklist({field, changeMapping, picklist, setPicklist, conversions}) {
    const { getDataModelPicklist, getPicklistRules, postPicklistRules, } = toolsDataModelApi();
    const {error, success} = useToolsNotifications();
    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const [processedPicklist, setProcessedPicklist] = useState(undefined);


    useEffect(() => {
        console.log(processedPicklist);
    }, [processedPicklist])

    useEffect(() => {
        if(field !== undefined && picklist !== undefined){
            let selectedPicklist = field.picklist;
            let copyOfObject = { ...picklist };
            if(conversions !== undefined) {
                Object.keys(conversions).map((value) => {
                    copyOfObject[selectedPicklist][conversions[value]].conversion = value;
                    copyOfObject[selectedPicklist][conversions[value]].edited = false;
                })
            }
            
            setProcessedPicklist(copyOfObject[selectedPicklist])  
        }
    }, [field])

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    console.log(picklist[field.picklist]);
    console.log(conversions)

    /* useEffect(() => {
        if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined) {
            getDataModelPicklist(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    let processed = {}
                    Object.keys(data.payload).map((pick) => {
                        processed[data.payload[pick][0].picklistId] = {}
                        data.payload[pick].map(elem => {
                            processed[elem.picklistId][elem.code] = elem;
                            processed[elem.picklistId][elem.code].conversion = '';
                            processed[elem.picklistId][elem.code].edited = false;
                        })
                    })
                    console.log(processed);
                    setPicklist(processed);
                }
            })
        }
    }, [selectedDataModel]) */

    const handleConversion = (value, pos) => {
        let copyOfObject = { ...picklist };
        copyOfObject[field.picklist][pos].conversion = value;
        copyOfObject[field.picklist][pos].edited = true;

        setPicklist(copyOfObject);
    }

    const savePickList = () => { 
        let conversionsLocal = {
            rules: {}
        }
        Object.keys(processedPicklist).map((fieldId) => {
            if(processedPicklist[fieldId].conversion !== undefined && processedPicklist[fieldId].conversion !== '') {
                conversionsLocal.rules[processedPicklist[fieldId].conversion] = fieldId;
            }
        })
        
        postPicklistRules(selectedDataModel.type, selectedDataModel.version, gpc, field.picklist, conversionsLocal).then((data) => {
            if(!data.error) {
                let copyOfObject = { ...picklist };
                Object.keys(copyOfObject[field.picklist]).map((code) => {
                    copyOfObject[field.picklist][code].edited = false;
                })
                setPicklist(copyOfObject) 
                setProcessedPicklist(copyOfObject[field.picklist]) 
                showUpdateMessage();
            }else {
                showUpdateMessageErr();
            }      
        }).catch((error) => {
            showUpdateMessageServerErr();
        });

        
    }

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    const buttonStyleDel = {
        backgroundColor: "#FFF",
        border: "solid 1px #E16C43",
        color: "#E16C43",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(225, 108, 67, 0.1)"
        }
    }

    return  <Box sx={{height: 700, width: "100%", borderRadius: "4px"}}>
                { picklist !== undefined ?
                    <Grid container item xs={12} rowSpacing={1} direction="column" paddingTop={"10px"}>
                        <Grid container item xs={12} rowSpacing={1} alignItems={"center"}>
                            {processedPicklist !== undefined ? 
                                Object.keys(processedPicklist).map((fieldId) => (
                                    <Grid container item xs={12} direction="row" columnSpacing={3} alignItems={"center"}>
                                        <Grid container item xs={6}>
                                            <TextField 
                                                label="Value"
                                                id={fieldId+"text"} 
                                                variant="outlined" 
                                                value={processedPicklist[fieldId].conversion}
                                                color={processedPicklist[fieldId].edited ? "warning" : processedPicklist[fieldId].conversion !== "" && processedPicklist[fieldId].conversion !== undefined ? "success" : ""}
                                                focused={processedPicklist[fieldId].edited ? true : processedPicklist[fieldId].conversion !== "" && processedPicklist[fieldId].conversion !== undefined ? true : false}
                                                onChange={(e) => {handleConversion(e.target.value, fieldId)}}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid container item xs={6}>
                                            {fieldId}
                                            <Tooltip title={processedPicklist[fieldId].englishInfo} placement="top" sx={{marginLeft: "5px"}}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))
                                :
                                    'Specific picklist for field ' + field.fieldId + ' not found.'
                            }
                        </Grid>   
                        <Grid item>
                            <Button 
                                sx={buttonStyle} 
                                onClick={savePickList}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                :
                    <Grid container width="100%" height="100%" alignContent={"center"} justifyContent={"center"}>
                        <Grid item>
                            <CircularProgress style={{width: "100px", height: "100px", color: "#d6e4ef"}} />
                        </Grid>
                    </Grid>
                }
            </Box>
}