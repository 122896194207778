import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import ProductTitle from "./ProductTitle"
import ProductWarnings from "./ProductWarnings";
import Notification from "./Notification";
import WorkspaceSection from "./WorkspaceSection";
import MappingSection from "./MappingSection";

export default function ProductUploadResult({mappingResponse, fileData, fileUploaded}) {
    const [postData, setPostData] = useState({"file": undefined, "key": undefined, "classification" : undefined})
    const [selectedKey, setSelectedKey] = useState(undefined)
    const [classification, setClassification] = useState(undefined)

    

    useEffect(() => {
        if(fileUploaded !== undefined) {
            setPostData({"file": fileUploaded, "key": selectedKey, "classification" : classification})
        }
    }, [fileUploaded, selectedKey, classification])

    useEffect(() => {
        if(mappingResponse.error === false) {
            setSelectedKey(mappingResponse.payload.key)
            setClassification(mappingResponse.payload.classification)
        }
    }, [mappingResponse])

    useEffect(() => {
        console.log(selectedKey)
    }, [selectedKey])

    const rightDivider = {
        borderRight: "solid 1px #666E71"
    }

    return  <Grid container item xs={12} width="100%" height="100%">
                <Grid item xs={3} style={rightDivider}>
                    <ProductTitle title={"Products"}/>
                    <ProductWarnings/>
                    {/* <Notification success={true}/> */}
                </Grid>
                <Grid item xs={5} style={rightDivider}>
                    <MappingSection headers={fileData !== undefined ? fileData[0] : []}  selectedKey={selectedKey} setSelectedKey={setSelectedKey} classification={classification} setClassification={setClassification} />
                </Grid>
                <Grid item container xs={4}>
                    <WorkspaceSection postData={postData} />
                </Grid>
            </Grid>
}   