import React from "react";
import { Grid, Typography, Box } from '@mui/material';
import ProductWarningLine from "./ProductWarningLine";

export default function ProductWarnings() {

    const warnings = [
        "Error super dificil de resolver",
        "Este es aun peor"
    ]

    return  <Grid container item xs={12} direction="column" width="100%" gap="20px">
                <Box sx={{
                    borderBottom: "solid 1px #3284C6",
                    marginLeft: "50px",
                    marginRight: "50px",
                    paddingTop: "25px",
                    paddingBottom: "20px"
                }}>
                    <Typography color="#E16C43" fontSize="17px" fontWeight="600" marginBottom="5px">Warnings</Typography>
                    <Typography color="#666E71" fontSize="12px" fontWeight="400" width="60%">Before exporting, make sure that all alerts are resolved.</Typography>
                </Box>
                <Grid item container direction="column" sx={{
                    paddingLeft: "50px",
                    gap: "5px"
                }}>
                    {warnings.map( message => {
                        return <ProductWarningLine message={message}/>
                    })}
                </Grid>
            </Grid>
}   